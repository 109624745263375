import React, {useEffect, useState} from "react"
import { Link } from "gatsby-plugin-intl"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import gift from "../../assets/images/reward/reward-gift.png"
import instance from "../../components/api/httpclient";
import MediaLayout from "../../components/media-layout";
import {useIntl} from "react-intl";
import {useMediaQuery} from "react-responsive";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
};

class BodyClass extends React.Component {
  static propTypes = {
    name: React.PropTypes
  }
  static defaultProps = {
    name: ''
  }
  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const CouponReady = ({location}) => {
  const [voucher, setVoucher] = useState({});
  const currentUser = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('currentUser')) : "";
  const intl = useIntl();

  useEffect(() => {

    if (location) {
      if (location.state) {
        setVoucher(location.state.voucherId);
      }
    }
  },[]);



  const getVoucher = (event) => {
    if (location) {
      if (location.state) {
        instance.get("/rewards/voucher/" + voucher,
          {
            headers: {
              'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
            },
            responseType: 'blob'
          })
          .then((response) => {
            console.log(response);
            const file = new Blob(
              [response.data],
              {type: 'application/pdf'});
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            if (window) {
              window.open(fileURL);
            }

          }).catch((error) => {
          console.log('error', error);
        });
      }
    }

    event.preventDefault();
  };

  const pageContent = () => {
    return(
      <div className="col-lg-8">
        <Link to="/rewards/reward-list" className="black mb-4 mt-0 back-link single-page"><span className="icon icon-chevron"></span>{intl.formatMessage({ id: "reward_shop.coupon.back" })}</Link>
        <div className="white-container text-center">
          <img src={gift} width="73" alt="Gift" />
          <h2>{intl.formatMessage({ id: "reward_shop.coupon.voucher_ready" })}</h2>
          <p>{intl.formatMessage({ id: "reward_shop.coupon.copy" })}<br/>
            {currentUser.email}
          </p>
          <p>
            {intl.formatMessage({ id: "reward_shop.coupon.terms" })}
          </p>
          <div className="mt-4">
            <Link to="#" onClick={(event => getVoucher(event))} className="button red">{intl.formatMessage({ id: "reward_shop.coupon.open_now" })}</Link>
          </div>
          <div className="mb-4 mt-4">
            <Link to="/rewards/reward-list" className="black back-link"><span className="icon icon-chevron"></span> <strong> {intl.formatMessage({ id: "reward_shop.coupon.back" })}</strong></Link>
          </div>
        </div>
      </div>
    )
  }

  return(
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.rewards.coupon-ready" })} />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={pageContent()} currentPage="reward" />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>

    </Layout>
  )
}

export default CouponReady
